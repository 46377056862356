import { useTranslation } from "next-i18next";
import { Button, Container } from "react-bootstrap";
import classNames from "classnames";
import classes from "./CookiesBar.module.scss";
import { useCookies } from "react-cookie";
import Script from "next/script";
import { useEffect, useState } from "react";

const getCookieExpireDate = () => {
  const cookieExpireDate = new Date();
  cookieExpireDate.setMonth(cookieExpireDate.getMonth() + 6);

  return cookieExpireDate;
};

interface Props extends React.HTMLProps<HTMLDivElement> { }

const CookiesBar = ({ className }: Props) => {
  const { t } = useTranslation("cookies");

  const [cookies, setCookie, removeCookie] = useCookies([
    "cookiesConsent",
    "_ga",
    `_ga_${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`,
  ]);
  const [showBar, setShowBar] = useState(false);
  const [addGoogleAnalytics, setAddGoogleAnalytics] = useState(false);
  useEffect(() => {
    setShowBar(!cookies.cookiesConsent);
    if (cookies.cookiesConsent === "true") {
      setAddGoogleAnalytics(true);
    }
  }, [cookies.cookiesConsent]);

  const cookiesAgreed = () => {
    setCookie("cookiesConsent", true, {
      path: "/",
      expires: getCookieExpireDate(),
    });
    setShowBar(false);
  };
  const cookiesDisagreed = () => {
    setCookie("cookiesConsent", false, {
      path: "/",
      expires: getCookieExpireDate(),
    });
    removeCookie("_ga");
    removeCookie(`_ga_${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`);
    setShowBar(false);
  };

  return (
    <>
      {showBar && (
        <Container className={classNames(classes.cookies, className)}>
          <p className={classNames(classes.text, className)}>{t("text")}</p>

          <Button
            className={classNames(classes.button, className)}
            onClick={cookiesAgreed}
          >
            {t("agree")}
          </Button>
          <Button
            className={classNames("px-0", classes.button, className)}
            onClick={cookiesDisagreed}
            variant="outline-light"
          >
            {t("disagree")}
          </Button>
        </Container>
      )}
      {addGoogleAnalytics && (
        <>
          <Script id="google-analytics-script">
            {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','G-${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');
            `}
          </Script>
          <Script id="google-analytics" strategy="afterInteractive">
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){window.dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}');
            `}
          </Script>
        </>
      )}
    </>
  );
};

export default CookiesBar;
