import "../styles/globals.scss";
import "react-toastify/dist/ReactToastify.css";
import type { AppProps } from "next/app";
import { ToastContainer } from "react-toastify";
import CookiesBar from "../components/utilities/CookiesBar/CookiesBar";
import { CookiesProvider } from "react-cookie";
import { Hydrate, QueryClientProvider } from "@tanstack/react-query";
import { queryClient } from "../api/queryClient";
import { Roboto } from 'next/font/google'
import { appWithTranslation } from "next-i18next";
import { DialogProvider } from "@providers/DialogProvider";

const roboto = Roboto({
  weight: ['100', '300', '400', '500', '700', '900'],
  style: ['normal', 'italic'],
  subsets: ['latin'],
  display: 'swap',
})

const MyApp = ({ Component, pageProps }: AppProps) => {
  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <CookiesProvider>
          <DialogProvider>
            <div className={roboto.className}>
              <Component  {...pageProps} />
            </div>
            <ToastContainer position="bottom-center" />
            <CookiesBar />
          </DialogProvider>
        </CookiesProvider>
      </Hydrate>
    </QueryClientProvider>
  );
};

export default appWithTranslation(MyApp);
