import React, { ReactNode, useContext, useState } from "react";
import { Modal, ModalProps } from "react-bootstrap";

interface DialogProviderType {
  openDialog: (input: DialogInput) => void;
  closeDialog: () => void;
}

export const DialogContext = React.createContext<DialogProviderType>({
  openDialog: () => { },
  closeDialog: () => { },
});

interface Props {
  children?: React.ReactNode;
}

export type DialogInput =
  | ({
    id: string;
    content: ReactNode;
    showClose?: boolean;
  } & Omit<ModalProps, "children">)
  | null;

export const DialogProvider = ({ children }: Props) => {
  const [dialogState, setDialogState] = useState<DialogInput[]>([]);

  const openDialog = (input: DialogInput) => {
    setDialogState((prevState) => [...prevState, input]);
  };

  const closeDialog = () => {
    setDialogState((prevState) => {
      const newState = [...prevState];
      newState.pop();
      return newState;
    });
  };

  const context = {
    openDialog,
    closeDialog,
  };
  return (
    <DialogContext.Provider value={context}>
      {children}
      {!!dialogState.length &&
        dialogState.map((dialog) => (
          <Modal
            {...dialog}
            show
            onHide={closeDialog}
            key={dialog?.id}
          >
            {dialog?.content}
          </Modal>
        ))}
    </DialogContext.Provider>
  );
};

export const useDialog = () => {
  return useContext(DialogContext);
};
